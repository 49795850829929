import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import useWindowSize from "./useWindowSize"
import { IoIosArrowDropdown } from "react-icons/io"
import { IoIosArrowDropup } from "react-icons/io"

const StyledHeader = styled.header`
  position: sticky;
  z-index: 2;
  width: 100vw;
  background: white;
  margin-bottom: 60px;
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black12};

  .logo {
    margin: 4px 6px 0 8px;
  }

  .nav-items,
  .mobileNav  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:12px;
    .nav {
      margin: 20px 20px 30px 20px;
      font-family: Poppins;
      font-size: 26px;
      font-weight: 600;
      a {
        text-decoration: none;
        color: #222222;
        text-transform: capitalize;
        font-family: Poppins;
        font-size: 26px;
        font-weight: 600;
        @media (max-width:1280px){
          font-weight: normal;
          font-size: 30px;
          line-height: 72px;
          font-weight:700;
        }
        @media (max-width:600px){
          font-size: 24px;
          line-height: 50px;
        }

        :hover {
          color: blue;
        }
      }
    }
    .dropdown-parent{
      position:relative;
      .dropdown-list{
        position: absolute;
        z-index: 100;
        background: white;
        left:0;
        // padding: 10px;
        width:250%;
        font-size: 20px;
        border-radius:8px;
        div{
          cursor:pointer;
          padding:15px;
        }
        div:hover{
          background:#ececec;
        }
      }
    }
    .selected-page {
      a {
        color: ${({ theme }) => theme.colors.black87};
      }
    }
  }

  .logo {
    max-width: 120px;
  }

  .burger {
    display: none;
  }

  .mobileNav {
    display: none;
  }
  @media (max-width:${({ theme }) =>
    theme.breakPoints.medium}) and (min-width:${({ theme }) =>
  theme.breakPoints.small}){
  .mobileNav {
    display: block;
  }
    .burger-tab{
      margin-left:-12rem;
      display:flex;
      align-items:center;
      justify-content:center;
      column-gap:10px;
      width:11rem;
      height:4rem;
      border: 1px solid #D1D1D1;
      background: #FFFFFF;
      border-radius:40px;
      padding:10px;
      
    .cross {
      position: relative;
      width: 50px;
      height: 50px;
      .line {
      position: absolute;
      border: 0.5px solid black;
      width: 30px;
      top: 35%;
      left: 50%;
      // transform-origin: center;
    }

    .line-1 {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .line-2 {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    }

    

      .menu{
        font-family: Poppins;
        font-size: 30px;
        line-height: 30px;
      }
      .line{
        border: 1.5px solid ${({ theme }) => theme.colors.black87};
          width: 28px;
          margin:8px;
          
      }
    }
      .flex-order{
        order:1;
      }
  }
 
  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {

    margin-bottom: 0px;
    .mobileNav {
      // background: ${({ theme }) => theme.colors.lightGrey};
      // border-bottom: 1px solid rgba(0,0,0,0.12);
      height: 0;
      display:block;
      
      transition: height .4s;

      .nav {
        margin: 0 0 0 20px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
      }
      .selected-page {
        a {
          color: ${({ theme }) => theme.colors.black87};
        }
      }
    }

    .logo {
      max-width: 160px;
    }

    .open_mobile_nav {
      height: 100%;
      .nav {
        visibility: visible;
        opacity: 1;
      }
        display: flex;
      position:absolute;
      flex-direction:column;
      align-items:flex-start;
      z-index:100;
      width:100%;
      height:100vh;
      background:white;
      justify-content:start;
      // row-gap:7px;
      .dropdown-list{
        @media (max-width:600px){
          .contact-dropdown{
          font-size:20px;
        }
        }
      }
      h4{
        font-family: Poppins;
        font-size: 80px;
        font-weight: 600;
        line-height: 72px;
        text-align: left;
        @media (max-width:600px){
          line-height:40px;
        }
      }
    }

    .nav-items {
      justify-content: space-between;
      .nav {
        display: none;
      }
      .burger {
        background: ${({ theme }) => theme.colors.white};
        margin: 20px;
        align-self: end;
        display: flex;
        width: 44px;
        height: 34px;
        border: 1px solid ${({ theme }) => theme.colors.black12};
        border-radius: 4px;
        padding: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .line {
          border: 0.5px solid ${({ theme }) => theme.colors.black87};
          width: 24px;
        }
        :hover {
          outline: ${({ theme }) => theme.colors.primary} auto 1px;
        }
        :focus {
          outline: ${({ theme }) => theme.colors.primary} auto 1px;
        }
      }
    }
`

const Header = ({ setShow, setModalName }) => {
  const [openMobileNav, setOpenMobileNav] = useState(false)
  const [currentEndpoint, setCurrentEndpoint] = useState("")
  const [showDropDown, setShowDropDown] = useState(false)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            headerPages
          }
        }
      }
    `
  )

  const { headerPages = [] } = site.siteMetadata
  const { isMedium, isXSmall, isSmall } = useWindowSize()
  const dropdownRef = useRef()
  useEffect(() => {
    // Check if window is defined (so if in the browser or in node.js).
    if (typeof window !== "undefined") {
      setCurrentEndpoint(
        window.location.pathname.replace(/\//g, "").toUpperCase()
      )
    }
  }, [])
  useEffect(()=>{
    function handleClickOutside(e){
      console.log(e.target)
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowDropDown(false)
      }
    }
    if (showDropDown){
      window.addEventListener('click',handleClickOutside)
    }
    return ()=>{
      window.removeEventListener('click',handleClickOutside)
    }
  },[showDropDown])
  const isSelectedPage = navItem => currentEndpoint === navItem.toUpperCase()

  function handleClick(e, text) {
    if (text === "Contact") {
      e.preventDefault()
      setShowDropDown(!showDropDown)
      // setShow(true)
    }
    else{
      setOpenMobileNav(false)
      // setTimeout(()=>{
      //   setOpenMobileNav(false)
      // },1000)
    }
  }
  function handleModalName(name) {
    setShow(true)
    setModalName(name)
  }

  return (
    <StyledHeader>
      <div className="nav-items">
        <div className="logo">
          <Link to="/" onClick={()=>{
            setOpenMobileNav(false)
          }}>
            {" "}
            <StaticImage
              className="logo"
              src="../images/logo.png"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Celink Logo"
              placeholder="dominantColor"
            />
          </Link>
        </div>
        <div>
          {!isMedium &&
            headerPages.map(navItem => {
              return (
                <div
                  key={navItem}
                  className={`nav${
                    isSelectedPage(navItem) ? " selected-page" : ""
                  } ${navItem === "Contact" ? "dropdown-parent" : ""}`}
                  ref={dropdownRef}
                >
                  <Link
                    className={`headline-6`}
                    to={`/${navItem.toLowerCase().replace(/ /g, "-")}`}
                    // to={`/${navItem.toLowerCase()}`}
                    onClick={e => {
                      handleClick(e, navItem)
                    }}
                  >
                    {navItem}
                    {navItem === "Contact" &&
                      (showDropDown ? (
                        <IoIosArrowDropdown />
                      ) : (
                        <IoIosArrowDropup />
                      ))}
                  </Link>
                  {navItem === "Contact" && (
                    <div
                      className="dropdown-list"
                      // style={{
                      //   position: "absolute",
                      //   zIndex: "100",
                      //   background: "white",
                      //   textAlign: "right",
                      // }}
                    >
                      {showDropDown && (
                        <>
                          <div
                            onClick={() => {
                              setShowDropDown(false)
                              handleModalName("borrowers")
                            }}
                          >
                            Information for Borrowers
                          </div>
                          <div
                            onClick={() => {
                              handleModalName("lenders")
                              setShowDropDown(false)
                            }}
                          >
                            Information for Lenders
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )
            })}
        </div>
        <div className="logo flex-order">
          <StaticImage
            className="logo"
            src="../images/financial_freedom.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Celink Logo"
            placeholder="dominantColor"
          />
        </div>
        {isSmall && (
          <button
            className="burger"
            onClick={() => setOpenMobileNav(!openMobileNav)}
          >
            <div className="line" />
            <div className="line" />
            <div className="line" />
          </button>
        )}

        {isMedium && !isSmall && (
          <button
            className="burger-tab"
            onClick={() => setOpenMobileNav(!openMobileNav)}
          >
            {!openMobileNav ? (
              <div>
                <div className="line" />
                <div className="line" />
                <div className="line" />
              </div>
            ) : (
              <div class="cross">
                <div class="line line-1"></div>
                <div class="line line-2"></div>
              </div>
            )}
            <div className="menu">{openMobileNav ? "CLOSE" : "MENU"}</div>
          </button>
        )}
      </div>
      <div className={`mobileNav${openMobileNav ? " open_mobile_nav" : ""}`}>
        {headerPages.map(navItem => {
          return (
            <h4
              key={navItem}
              className={`bold nav${
                isSelectedPage(navItem) ? " selected-page" : ""
              }`}
            >
              <Link
                className={`headline-6`}
                to={`/${navItem.toLowerCase().replace(/ /g, "-")}`}
                onClick={e => {
                  handleClick(e, navItem)
                }}
              >
                {navItem}
                {navItem === "Contact" &&
                  (showDropDown ? (
                    <IoIosArrowDropdown />
                  ) : (
                    <IoIosArrowDropup />
                  ))}
              </Link>
              {navItem === "Contact" && (
                <div
                  className="dropdown-list"
                >
                  {showDropDown && (
                    <>
                      <div
                        onClick={() => {
                          setOpenMobileNav(false)
                          setShowDropDown(false)
                          handleModalName("borrowers")
                        }}
                        className="contact-dropdown"
                      >
                        Information for Borrowers
                      </div>
                      <div
                        onClick={() => {
                          setOpenMobileNav(false)
                          setShowDropDown(false)
                          handleModalName("lenders")
                        }}
                        className="contact-dropdown"
                      >
                        Information for Lenders
                      </div>
                    </>
                  )}
                </div>
              )}
            </h4>
          )
        })}
      </div>
    </StyledHeader>
  )
}

export default Header
