import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "./Grid"

const StyledFooter = styled.footer`
  width: 99vw;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 36px 0 0 0;
  margin-top: 48px;
  background: #ecf2ff;

  border-top: 1px solid ${({ theme }) => theme.colors.black12};
  .footer-grid {
    margin-left: 20px;
  }
  .nav-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 15px;
    .nav {
      margin: 0 8px;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-top: 25px;
      a {
        text-transform: capitalize;
        text-decoration: none;
        font-weight: 400;
        font-family: Poppins;
        font-size: 17px;
        font-weight: 700;
        color: #444444;

        :hover {
          color: blue;
        }
      }
    }
    .vert-break-line {
      color: ${({ theme }) => theme.colors.black87};
    }
  }

  .label {
    margin: 0 0 18px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #444444;
    margin-top: 15px;
    @media (max-width: 1280px) {
      line-height: 32px;
    }
    a {
      color:#444444;
      text-decoration-line:none;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      :hover{
        color:blue;
      }
    }
  }

  .header-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 28px;
    margin-top: 28px;
    border-top: 0.5px solid ${({ theme }) => theme.colors.black12};

    .vert-break-line {
      margin: 0 4px;
    }

    .header-nav-item {
      margin: 0;
      a {
        text-transform: capitalize;
        text-decoration: none;
        font-size: 18px;
        font-weight: normal;
        color: ${({ theme }) => theme.colors.black87};
        :hover {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  .all-rights {
    margin-bottom: 6px;
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    .nav-items {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .nav-items {
      flex-direction: column;
      align-items: flex-start;

      .nav {
        margin-top: 8px;
        margin-left: 0;
      }
    }

    .vert-break-line {
      display: none;
    }

    .header-nav {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`
const EXTERNAL_LINKS = {
  "Foreclosure Avoidance/Loss Mitigation":"https://www.reversedepartment.com/foreclosure-avoidance",
  "Language Assistance": "https://www.reversedepartment.com/nydca",
  "Important Disclosures": "https://www.reversedepartment.com/foreclosure-avoidance"
}

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            headerPages
            footerPages
          }
        }
      }
    `
  )

  const { footerPages = [] } = site.siteMetadata

  return (
    <StyledFooter>
      <Grid
        gridcolumnlayout={{ sm: "1/9", lg: "1/15", xl: "1/15" }}
        className={"footer-grid"}
      >
        <div className="nav-items">
          {footerPages.map((nav, index) => {
            return (
              <React.Fragment key={nav + index}>
                <h6 key={nav} className="nav">
                {EXTERNAL_LINKS[nav] 
                  ? <a target="_blank" href={EXTERNAL_LINKS[nav]}>{nav}</a> 
                  : <Link
                    className="headline-6"
                    to={`/${nav.toLowerCase().replace(/ /g, "-")}`}
                  >
                    {nav}
                  </Link>
                }
                </h6>
                
              </React.Fragment>
            )
          })}
        </div>
        <h6 className="label">
          NMLS #3020 - <a href="https://nmlsconsumeraccess.org/" target="_blank">http://nmlsconsumeraccess.org</a></h6>

        <h6 className="label">
          For loans that are secured by real estate located in Texas: COMPLAINTS
          REGARDING THE SERVICING OF YOUR MORTGAGE SHOULD BE SENT TO THE
          DEPARTMENT OF SAVINGS AND MORTGAGE LENDING 2601 NORTH LAMAR, SUITE
          201, AUSTIN, TX 78705. A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT
          877-276-5550. A complaint form and instructions may be downloaded and
          printed from the Department’s website located at www.sml.texas.gov or
          obtained from the department upon request by mail at the address
          above, by telephone at its toll-free consumer hotline listed above, or
          by email at smlinfo@sml.texas.gov
        </h6>
        <h6 className="label all-rights">
          © 2016-2025 Compu-Link Corporation D/B/A Celink (WA license# CL3020
          and 603 018 607). All rights reserved.
        </h6>
      </Grid>
    </StyledFooter>
  )
}

export default Footer
