import * as React from "react"
import { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"
import Theme from "./Theme"
import StyleGuide from "./StyleGuide"
import Header from "./header"
import Footer from "./footer"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaTimes } from "react-icons/fa"
import axios from 'axios';

const StyledMain = styled.main`
  position: relative;

  background: ${({ theme }) => theme.colors.white};
  min-height: 55vh;
  margin-top: 30px;

  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    padding-top: 50px;
  }
`
const CloseButton = styled.button`
  
    width: 40px;
    height: 40px;
    background-color: #99b4d1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1050;

    &:hover {
      background-color: #99b4d1 ;
    }

`
const StyleWrapper = styled.div`
  padding: 0px;
  border-radius: 20px !important;
  position: relative;

  .modal-body-container {
    padding: 40px;
    font-family: 'Poppins';
  }

  .close-button {
    width: 40px;
    height: 40px;
    background-color: orange;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1050;

    &:hover {
      background-color: #ff9f50;
    }
  }

  .close-icon {
    color: white;
    font-size: 20px;
  }

  .modal-body {
    padding: 0px !important;
  }
  h3 {
    font-family: Poppins;
    font-weight: 700;
    size: 34px;
    line-height: 28px;
    // text-align: center;
    margin-bottom: 0px;
    margin-left: 40px;
  }
  p {
    font-family: Poppins;
    font-weight: 400;
    size: 20px;
    line-height: 32px;
    // text-align: center;
    margin-left: 40px;
    margin-bottom: 10px;
  }
  label {
    font-family: Poppins;
    font-weight: 400;
    size: 16px;
    line-height: 22px;
  }
  .submit-btn {
    grid-column: 2 / -1;
    text-align: right;
    padding-left: 120px;
    margin-right: 40px;
  }
`

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 25px;
    overflow: hidden;
    position: relative;
  }
  .modal-dialog {
    @media (max-width: ${({ theme }) =>
        theme.breakPoints.medium}) and (min-width: ${({ theme }) =>
        theme.breakPoints.small}) {
      max-width: 100%;
      width: 98%;
      padding: auto;
      margin: auto;
      margin-top: 0px !important;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
    .contact-us {
      display: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    .modal-content {
      border-radius: 35px;
      overflow: hidden;
    }
    .contact-us {
      margin-bottom: 20px;
    }
    // .modal-dialog {
    //   @media (max-width: 1280px) and (min-width: 600px) {
    //     backgroud: red;
    //     width: 98%;
    //     padding: auto;
    //     margin: auto;
    //     margin-top: 0px !important;
    //   }
    // }
    .company-class {
      grid-column: 1 / 3;
      margin-right: 40px;
    }

    .address-class {
      grid-column: 1 / 3;
      margin-left: 40px;
    }
    .first-class {
      grid-column: 1 / 3;
      margin-right: 40px;
    }
    .last-class {
      grid-column: 1 / 3;
      margin-left: 40px;
    }
    .email-class {
      grid-column: 1 / 3;
      margin-right: 40px;
    }
    .phone-class {
      grid-column: 1 / 3;
      margin-left: 40px;
    }
    .submit-class {
      grid-column: 1 / 3 !important;
      width: 96% !important;
      padding-left: 40px !important;
      @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
        width: 90%;
        padding-right: 15px;
      }
    }
    .submit-button-cls {
      width: 100% !important;
    }
  }
`
const Layout = ({ children }) => {
  const [show, setShow] = useState(false)
  const [modalName, setModalName] = useState("")
  const handleClose = e => {
    if (e === undefined || e.target.name === undefined || !e.target.name) {
      setShow(false)
      return
    }

    if (e.target.name === "contact") {
      setShow(false)
      // navigate("/contact")
      window.open('https://www.reversedepartment.com/contact', '_blank');
    }

    if (e.target.name === "compulink") {
      setShow(false)
      navigate("/compu-link")
    }
  }
  const StyledModal = styled(Modal)`
    .modal-content {
      border-radius: 25px;
      overflow: hidden;
      position: relative;
    }
    .modal-dialog {
      @media (max-width: ${({ theme }) =>
          theme.breakPoints.medium}) and (min-width: ${({ theme }) =>
          theme.breakPoints.small}) {
        max-width: 100%;
        width: 98%;
        padding: auto;
        margin: auto;
        margin-top: 0px !important;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
      .contact-us {
        display: none;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
      .modal-content {
        border-radius: 35px;
        overflow: hidden;
      }
      .contact-us {
        margin-bottom: 20px;
      }
      // .modal-dialog {
      //   @media (max-width: 1280px) and (min-width: 600px) {
      //     backgroud: red;
      //     width: 98%;
      //     padding: auto;
      //     margin: auto;
      //     margin-top: 0px !important;
      //   }
      // }
      .company-class {
        grid-column: 1 / 3;
        margin-right: 40px;
      }

      .address-class {
        grid-column: 1 / 3;
        margin-left: 40px;
      }
      .first-class {
        grid-column: 1 / 3;
        margin-right: 40px;
      }
      .last-class {
        grid-column: 1 / 3;
        margin-left: 40px;
      }
      .email-class {
        grid-column: 1 / 3;
        margin-right: 40px;
      }
      .phone-class {
        grid-column: 1 / 3;
        margin-left: 40px;
      }
      .submit-class {
        grid-column: 1 / 3 !important;
        width: 96% !important;
        padding-left: 40px !important;
        @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
          width: 90%;
          padding-right: 15px;
        }
      }
      .submit-button-cls {
        width: 100% !important;
      }
    }
  `
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setShow, setModalName });
    }
    return child;
  });

  const submitContactUsDetails = async(contactUsDetails) => {
    let {
    company_name, 
     company_address,
     given_name,
     family_name,
     phone_number,
     work_email
   } = contactUsDetails;
   
    axios.post(process.env.GATSBY_EXTERNAL_INVOKE_URL_CORP_CONTACT_REQUEST,    {
      company_name: company_name.value, 
      company_address:company_address.value,
      given_name:given_name.value,
      family_name: family_name.value,
      phone_number:phone_number.value,
      work_email:work_email.value
    }, {
      headers: {
        "x-api-key":process.env.GATSBY_EXTERNAL_API_KEY_VALUE
      },
    }).then(success => {
      setModalName('success');
    }, er => {
      console.error('Error while sending corp contact request email');
    });
  }
  const submitContactFormHanlder =  e => {
    e.preventDefault()
    submitContactUsDetails(e.currentTarget.elements)
  }
  return (
    <Theme>
      <StyleGuide>
        <Header setShow={setShow} setModalName={setModalName} />
        <StyledMain>{childrenWithProps}</StyledMain>
        <Footer />
        <Modal
          show={show && modalName == "borrowers"}
          onHide={handleClose}
          dialogClassName="custom-modal"
          centered
        >
        <CloseButton className="close-button" onClick={handleClose}>
              <FaTimes className="close-icon" />
            </CloseButton>
          <Modal.Body style={{padding:"28px"}}>
            Do you need assistance with a reverse mortgage loan administered by
            Compu-Link on behalf of HUD?
            <br />
            <em>
              (If the loan is being administered by Compu-Link on behalf of HUD,
              the logo and address below will appear on the borrower’s account
              statement)
            </em>
            <br />
            <StaticImage
              className=""
              src={"../images/compu-link.png"}
              alt="compu-link information"
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="dominantColor"
              layout="constrained"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" name={"compulink"} onClick={handleClose}>
              Yes, the loan is being administered by Compu-Link on behalf of HUD
            </Button>
            <Button variant="primary" name={"contact"} onClick={handleClose}>
              No, the loan is serviced by another company
            </Button>
          </Modal.Footer>
        </Modal>
        <StyledModal
          show={show && modalName == "success"}
          onHide={handleClose}
          size="lg"
          dialogClassName="custom-modal"
          className="model-class"
          centered
        >
          <StyleWrapper>
            {/* <Modal.Header closeButton></Modal.Header> */}
            <button className="close-button" onClick={handleClose}>
              <FaTimes className="close-icon" />
            </button>
      
            <Modal.Body className="modal-cls" >
            <div className="modal-body-container">
                Thank you. Your inquiry has been received, and our team will respond back to you as soon as possible.
              </div>
            </Modal.Body>
            
          </StyleWrapper>
        </StyledModal>

        <StyledModal
          show={show && modalName == "lenders"}
          onHide={handleClose}
          size="lg"
          dialogClassName="custom-modal"
          className="model-class"
          centered
        >
          <StyleWrapper>
            {/* <Modal.Header closeButton></Modal.Header> */}
            <button className="close-button" onClick={handleClose}>
              <FaTimes className="close-icon" />
            </button>
            <Modal.Body className="modal-cls" style={{ padding: 0 }}>
              <StaticImage
                className="contact-us"
                src={"../images/contact-us.png"}
                alt="compu-link information"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="dominantColor"
                layout="constrained"
                style={{ padding: "0px !important" }}
              />
              <h3>Interested in Celink’s Subservicing Solutions?</h3>
              <p>
                Submit the form below to learn more about a partnership with
                Celink.
              </p>
              <form
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "15px",
                  // width: "978px",
                  // height: "270px",
                  // top: "658px",
                  // left: "521px",
                  border: "1px",
                }}
                onSubmit={submitContactFormHanlder}
              >
                {/* Company Name */}
                <div style={{ marginLeft: "40px" }} className="company-class">
                  <label htmlFor="companyName" style={{ display: "block" }}>
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    name="company_name"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "10px",
                      height: "45px",
                      border: "1px solid",
                      borderColor: "#D2D9EA",
                    }}
                    required
                  />
                </div>

                {/* Company Address */}
                <div style={{ marginRight: "40px" }} className="address-class">
                  <label htmlFor="companyAddress" style={{ display: "block" }}>
                    Company Address
                  </label>
                  <input
                    type="text"
                    id="companyAddress"
                    name="company_address"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "10px",
                      height: "45px",
                      border: "1px solid",
                      borderColor: "#D2D9EA",
                    }}
                    required
                  />
                </div>
                {/* First Name */}
                <div style={{ marginLeft: "40px" }} className="first-class">
                  <label htmlFor="firstName" style={{ display: "block" }}>
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="given_name"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "10px",
                      height: "45px",
                      border: "1px solid",
                      borderColor: "#D2D9EA",
                    }}
                    required
                  />
                </div>

                {/* Last Name */}
                <div style={{ marginRight: "40px" }} className="last-class">
                  <label htmlFor="lastName" style={{ display: "block" }}>
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="family_name"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "10px",
                      height: "45px",
                      border: "1px solid",
                      borderColor: "#D2D9EA",
                    }}
                    required
                  />
                </div>

                {/* Work Email */}
                <div style={{ marginLeft: "40px" }} className="email-class">
                  <label htmlFor="email" style={{ display: "block" }}>
                    Work Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="work_email"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "10px",
                      height: "45px",
                      border: "1px solid",
                      borderColor: "#D2D9EA",
                    }}
                    required
                  />
                </div>

                {/* Phone Number */}
                <div style={{ marginRight: "40px" }} className="phone-class">
                  <label htmlFor="phoneNumber" style={{ display: "block" }}>
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phone_number"
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "10px",
                      height: "45px",
                      border: "1px solid",
                      borderColor: "#D2D9EA",
                    }}
                  />
                </div>
                <div className="submit-btn submit-class">
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "#ED7D30",
                      color: "white",
                      padding: "10px 23px",
                      border: "none",
                      borderRadius: "40px",
                      cursor: "pointer",
                    }}
                    className="submit-button-cls"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Modal.Body>
          </StyleWrapper>
        </StyledModal>
      </StyleGuide>
    </Theme>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout